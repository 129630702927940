import React, { Fragment, useEffect, useContext, useState } from 'react'
import { Main as MainPosition } from './position/Main'
import { Progressbar } from './position/Progressbar'
import { EthereumContext } from '../context/ethereum/ethereumContext'
import { useWeb3Context } from 'web3-react'
import { Footer } from './footer/Footer'
import { Pagination } from './Pagination'
import { Loader } from './Loader'

export const Positions = ({ isArchived }) => {
  const { positions, fetchPositions, onVote } = useContext(EthereumContext)
  const { account, library } = useWeb3Context()
  const [currentPage, setCurrentPage] = useState(1)
  const [positionsPerPage] = useState(5)

  useEffect(() => {
    fetchPositions({ web3: library, from: account })
  }, [])

  const paginate = pageNumber => setCurrentPage(pageNumber)

  const filteredPositions = []
  positions.map(position => {
    if ((position.archived === isArchived) & !isArchived & (position.finishedAt > new Date() / 1000)) {
      filteredPositions.push({ position, isDone: false })
    } else if ((position.archived === isArchived) & isArchived) {
      filteredPositions.push({ position, isDone: true })
    } else if (isArchived & (position.finishedAt < new Date() / 1000)) {
      filteredPositions.push({ position, isDone: true })
    }
  })

  const indexOfLastPosition = currentPage * positionsPerPage
  const indexOfFirstPosition = indexOfLastPosition - positionsPerPage
  const currentPositions = filteredPositions.slice(indexOfFirstPosition, indexOfLastPosition)

  return (
    <Fragment>
      {filteredPositions.length >= 0 ? (
        <div>
          <ul className="list-group">
            {currentPositions.map(({ position, isDone }) => (
              <div key={position.id} className="positions-custom">
                <MainPosition isDone={isDone} onVote={onVote} position={position} />
                <Progressbar position={position} />
              </div>
            ))}
          </ul>
          <Pagination
            positionsPerPage={positionsPerPage}
            totalPositions={filteredPositions.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        </div>
      ) : <Loader />}
      {filteredPositions.length > 0 ? <Footer /> : null}
    </Fragment>
  )
}
