import React, { useReducer } from 'react'

import { EthereumContext } from './ethereumContext'
import { EthereumReducer } from './ethereumReducer'
import { FETCH_POSITIONS, SHOW_LOADER, FETCH_BALANCE } from '../constants'

import { LOGIC_CONTRACT_ADDRESS, LOGIC_CONTRACT_ABI } from './contract'
import { TOKEN_CONTRACT_ADDRESS, TOKEN_CONTRACT_ABI } from './token'

export const EthereumState = ({ children }) => {
  const initialState = {
    positions: [],
    loading: false,
    balance: null
  }
  const [state, dispatch] = useReducer(EthereumReducer, initialState)

  const showLoader = () => dispatch({ type: SHOW_LOADER })

  const fetchBalance = async ({ web3, from }) => {
    const tokenContract = new web3.eth.Contract(TOKEN_CONTRACT_ABI, TOKEN_CONTRACT_ADDRESS)

    const balance = await tokenContract.methods.balanceOf(from).call({ from })
    const payload = { balance }
    dispatch({ type: FETCH_BALANCE, payload })
  }

  const fetchPositions = async ({ web3, from }) => {
    const logicContract = new web3.eth.Contract(LOGIC_CONTRACT_ABI, LOGIC_CONTRACT_ADDRESS)

    const positionLength = Number(await logicContract.methods.positionAmount().call())

    const positions = []
    for (let i = 0; i < positionLength; i++) {
      const [details, numbers, voterInfo] = await Promise.all([
        logicContract.methods.positionDetails(i).call(),
        logicContract.methods.postionNumbers(i).call(),
        logicContract.methods.voterInfo(from, i).call(),
      ])

      positions.push({
        ...details,
        ...numbers,
        voterInfo,
        id: i
      })
    }

    positions.map(position => {
      position.capInUSD = Number(position.capInUSD)
      position.voteNo = Number(position.voteNo)
      position.votePriceInTokens = Number(position.votePriceInTokens)
      position.voteYes = Number(position.voteYes)
      return position
    })

    const payload = { positions: positions.reverse() }

    dispatch({ type: FETCH_POSITIONS, payload })
  }

  const addPostion = ({ values, contract, from, utils }) => {
    const data = {
      ...values,
      finishedAt: values.finishedAt.getTime() / 1000
    }

    contract.methods
      .addNewPostition(
        data.name,
        data.description,
        data.imageUrl,
        data.link,
        data.capInUSD,
        utils.toWei(data.votePriceInTokens.toString(), 'ether'),
        data.finishedAt
      )
      .send({ from })
      .on('transactionHash', console.log)
      .on('receipt', console.log)
  }

  const editPosition = ({ values, contract, from, utils }) => {
    const data = {
      ...values,
      finishedAt: values.finishedAt.getTime() / 1000
    }

    contract.methods
      .editPosition(
        data.id - 1,
        data.name,
        data.description,
        data.imageUrl,
        data.link,
        data.capInUSD,
        utils.toWei(data.votePriceInTokens.toString(), 'ether'),
        data.finishedAt
      )
      .send({ from })
      .on('transactionHash', console.log)
      .on('receipt', console.log)
      .on('error', console.error)
  }

  const archivePosition = ({ values, contract, from }) => {
    const data = values

    contract.methods
      .changeStatus(data.id - 1)
      .send({ from })
      .on('transactionHash', console.log)
      .on('receipt', console.log)
      .on('error', console.error)
  }

  const withdrawTokens = ({ values, contract, from, utils }) => {
    const data = values

    contract.methods
      .withdrawTokens(data.address, utils.toWei(data.amount.toString(), 'ether'))
      .send({ from })
      .on('transactionHash', console.log)
      .on('receipt', console.log)
      .on('error', console.error)
  }

  const onVote = async ({ values, contract, from, web3 }) => {
    const logicContract = new web3.eth.Contract(LOGIC_CONTRACT_ABI, LOGIC_CONTRACT_ADDRESS)
    const bytes = await logicContract.methods.parseTwoUint256ToBytes(values.id, values.vote).call()

    await contract.methods
      .approveAndCall(LOGIC_CONTRACT_ADDRESS, values.tokens, bytes)
      .send({ from })
      .on('receipt', async () => {
        fetchPositions({ web3, from })
      })
  }

  return (
    <EthereumContext.Provider
      value={{
        showLoader,
        addPostion,
        editPosition,
        fetchPositions,
        fetchBalance,
        archivePosition,
        withdrawTokens,
        onVote,
        loading: state.loading,
        positions: state.positions,
        balance: state.balance
      }}
    >
      {children}
    </EthereumContext.Provider>
  )
}
