import React from 'react'

export const Pagination = ({ positionsPerPage, totalPositions, paginate, currentPage }) => {
  const pageNumbers = []
  for (let i = 1; i <= Math.ceil(totalPositions / positionsPerPage); i++) {
    pageNumbers.push(i)
  }

  return (
    <nav className="d-flex justify-content-center pagination-custom">
      <ul className="pagination">
        {pageNumbers.map(number => {
          if (number === currentPage) {
            return (
              <li key={number} className="page-item active">
                <a onClick={() => paginate(number)} className="page-link">
                  {number}
                </a>
              </li>
            )
          } else {
            return (
              <li key={number} className="page-item">
                <a onClick={() => paginate(number)} className="page-link">
                  {number}
                </a>
              </li>
            )
          }
        })}
      </ul>
    </nav>
  )
}
