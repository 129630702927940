import React from 'react'
import { Right } from './Right'
import { Vote } from './Vote'
import ReadMoreReact from 'read-more-react'

const readMoreq = (
  <div className="read-more-button-a">
    <a>Read more</a>
  </div>
)

export const Main = ({ isDone, onVote, position }) => (
  <div className="container">
    <li className="list-group-item position">
      <div className="col-sm-6 align-self-stretch">
        <div className="position-main-left-title">{position.name}</div>
        <div className="position-main-left-description">
          <ReadMoreReact text={position.description || position.desctiption} min={100} ideal={195} max={999999} readMoreText={readMoreq} />
        </div>

        <Vote isDone={isDone} onVote={onVote} position={position} />
      </div>
      <div className="col-sm-6 align-self-stretch">
        <Right isDone={isDone} position={position} />
      </div>
    </li>
  </div>
)
