import { Connectors } from 'web3-react'

const { InjectedConnector, NetworkOnlyConnector } = Connectors

const MetaMask = new InjectedConnector({ supportedNetworks: [1, 3] })

const supportedNetworkURLs = {
  1: 'https://mainnet.infura.io/v3',
  3: 'https://ropsten.infura.io'
}

const Infura = new NetworkOnlyConnector({
  providerURL: supportedNetworkURLs[1]
})

export const connectors = { MetaMask, Infura }
