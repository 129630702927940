import React from 'react'
import { useFormik } from 'formik'
import { useWeb3Context } from 'web3-react'

import {
  LOGIC_CONTRACT_ADDRESS,
  LOGIC_CONTRACT_ABI
} from '../../context/ethereum/contract'

export const WithdrawTokens = ({ active, onWithdraw }) => {
  const context = useWeb3Context()

  const formik = useFormik({
    initialValues: {
      address: '',
      amount: 0
    },
    onSubmit: values => {
      const logicContract = new context.library.eth.Contract(
        LOGIC_CONTRACT_ABI,
        LOGIC_CONTRACT_ADDRESS
      )

      onWithdraw({
        values,
        contract: logicContract,
        from: context.account,
        utils: context.library.utils
      })
    }
  })

  return (
    <div>
      <h1>Withdraw tokens</h1>
      <form onSubmit={formik.handleSubmit}>
        <div className="text-input">
          <label htmlFor="address">Address &nbsp;&nbsp;</label>
          <input
            id="address"
            name="address"
            type="string"
            onChange={formik.handleChange}
            value={formik.values.address}
          />
        </div>

        <div className="text-input">
          <label htmlFor="amount">Amount &nbsp;&nbsp;</label>
          <input
            id="amount"
            name="amount"
            type="number"
            onChange={formik.handleChange}
            value={formik.values.amount}
          />
        </div>

        <button disabled={!active} type="submit">
          Submit
        </button>
      </form>
    </div>
  )
}
