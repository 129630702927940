import React from 'react'

export const Progressbar = ({ position }) => {
  const totalVotes = position.voteNo + position.voteYes
  const totalTime = position.finishedAt - position.createdAt
  const currentTime = new Date() / 1000 - position.createdAt

  let percentage = Math.round((currentTime / totalTime) * 100)
  if (percentage > 100) {
    percentage = 100
  } else if (percentage < 0) {
    percentage = 0
  }

  const shrinker = {
    width: `${100 - percentage}%`,
    backgroundColor: 'white',
    position: 'absolute',
    top: 0,
    right: 0
  }

  return (
    <div className="progressbar-custom">
      <div className="container">
        <div className="row no-gutters">
          <div className="col-sm-6">
            <div className="progressbar-custom-text-left">{`${percentage}%`}</div>
          </div>
          <div className="col-sm-6">
            <div className="progressbar-custom-text-right">
              Total votes: {<strong>{totalVotes}</strong>} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Votes YES:{' '}
              {<strong>{position.voteYes}</strong>} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Votes NO:{' '}
              {<strong>{position.voteNo}</strong>}
            </div>
          </div>
        </div>
        <div id="progress-bar-container">
          <div className="progress-bar-child progress"></div>
          <div className={'progress-bar-child'} style={shrinker}></div>
        </div>
      </div>
    </div>
  )
}
