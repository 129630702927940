import React from 'react'
import { HashRouter as BrowserRouter, Route, Switch } from 'react-router-dom'
import { Home } from './pages/Home'
import { Admin } from './pages/Admin'
import { Navbar } from './components/navbar/Navbar'
import { Archive } from './pages/Archive'
import { EthereumState } from './context/ethereum/EthereumState'
import Web3Provider from 'web3-react'
import { connectors } from './connectors'
import Web3 from 'web3'

const App = () => (
  <Web3Provider connectors={connectors} libraryName="web3.js" web3Api={Web3}>
    <EthereumState>
      <BrowserRouter>
        <Navbar />
        <div>
          <Switch>
            <Route path={'/'} exact component={Home} />
            <Route path={'/archive'} component={Archive} />
            <Route path={'/admin'} component={Admin} />
          </Switch>
        </div>
      </BrowserRouter>
    </EthereumState>
  </Web3Provider>
)

export default App
