import React from 'react'
import { Web3Consumer } from 'web3-react'

export const Web3ConsumerComponent = () => {
  return (
    <Web3Consumer>
      {context => {
        const { active, connectorName, account, networkId } = context
        return (
          active && (
            <React.Fragment>
              <p>
                Active Connector: {connectorName} | Account: {account || 'None'}{' '}
                | Network ID: {networkId}
              </p>
            </React.Fragment>
          )
        )
      }}
    </Web3Consumer>
  )
}
