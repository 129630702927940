export const LOGIC_CONTRACT_ADDRESS = '0x7955546004e1c35b25099c704d5f0db88f9405a6'
export const LOGIC_CONTRACT_ABI = [
  {
    constant: false,
    inputs: [
      {
        internalType: 'string',
        name: '_name',
        type: 'string'
      },
      {
        internalType: 'string',
        name: '_description',
        type: 'string'
      },
      {
        internalType: 'string',
        name: '_imageUrl',
        type: 'string'
      },
      {
        internalType: 'string',
        name: '_link',
        type: 'string'
      },
      {
        internalType: 'uint256',
        name: '_capInUSD',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: '_votePriceInTokens',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: '_finishedAt',
        type: 'uint256'
      }
    ],
    name: 'addNewPostition',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        internalType: 'uint256',
        name: 'toPosition',
        type: 'uint256'
      }
    ],
    name: 'changeStatus',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        internalType: 'uint256',
        name: '_positionIndex',
        type: 'uint256'
      },
      {
        internalType: 'string',
        name: '_name',
        type: 'string'
      },
      {
        internalType: 'string',
        name: '_description',
        type: 'string'
      },
      {
        internalType: 'string',
        name: '_imageUrl',
        type: 'string'
      },
      {
        internalType: 'string',
        name: '_link',
        type: 'string'
      },
      {
        internalType: 'uint256',
        name: '_capInUSD',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: '_votePriceInTokens',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: '_finishedAt',
        type: 'uint256'
      }
    ],
    name: 'editPosition',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        internalType: 'address',
        name: '_from',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: '_tokens',
        type: 'uint256'
      },
      {
        internalType: 'address',
        name: '_token',
        type: 'address'
      },
      {
        internalType: 'bytes',
        name: '_data',
        type: 'bytes'
      }
    ],
    name: 'receiveApproval',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        internalType: 'address',
        name: 'newOwner',
        type: 'address'
      }
    ],
    name: 'transferOwnership',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        internalType: 'address',
        name: '_wallet',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: '_tokens',
        type: 'uint256'
      }
    ],
    name: 'withdrawTokens',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_token',
        type: 'address'
      }
    ],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'constructor'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address'
      }
    ],
    name: 'OwnershipTransferred',
    type: 'event'
  },
  {
    constant: true,
    inputs: [],
    name: 'iskraToken',
    outputs: [
      {
        internalType: 'contract ISKRA',
        name: '',
        type: 'address'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'owner',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      {
        internalType: 'bytes',
        name: 'data',
        type: 'bytes'
      }
    ],
    name: 'parse64BytesToTwoUint256',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    payable: false,
    stateMutability: 'pure',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      {
        internalType: 'uint256',
        name: 'x',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'y',
        type: 'uint256'
      }
    ],
    name: 'parseTwoUint256ToBytes',
    outputs: [
      {
        internalType: 'bytes',
        name: 'b',
        type: 'bytes'
      }
    ],
    payable: false,
    stateMutability: 'pure',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'positionAmount',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      {
        internalType: 'uint256',
        name: '_index',
        type: 'uint256'
      }
    ],
    name: 'positionDetails',
    outputs: [
      {
        internalType: 'string',
        name: 'name',
        type: 'string'
      },
      {
        internalType: 'string',
        name: 'description',
        type: 'string'
      },
      {
        internalType: 'string',
        name: 'imageUrl',
        type: 'string'
      },
      {
        internalType: 'string',
        name: 'link',
        type: 'string'
      },
      {
        internalType: 'bool',
        name: 'archived',
        type: 'bool'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      {
        internalType: 'uint256',
        name: '_index',
        type: 'uint256'
      }
    ],
    name: 'postionNumbers',
    outputs: [
      {
        internalType: 'uint256',
        name: 'capInUSD',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'votePriceInTokens',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'finishedAt',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'createdAt',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'voteYes',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'voteNo',
        type: 'uint256'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      {
        internalType: 'address',
        name: '_voter',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: '_position',
        type: 'uint256'
      }
    ],
    name: 'voterInfo',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  }
]
