import React, { useContext } from 'react'
import { Create } from '../components/admin/create'
import { Edit } from '../components/admin/edit'
import { Archive } from '../components/admin/archive'
import { WithdrawTokens } from '../components/admin/withdrawTokens'
import { EthereumContext } from '../context/ethereum/ethereumContext'
import { useWeb3Context } from 'web3-react'
import { Web3ConsumerComponent } from './Web3ConsumerComponent'
import { Footer } from '../components/footer/Footer'

export const Admin = () => {
  const { addPostion, editPosition, archivePosition, withdrawTokens } = useContext(EthereumContext)

  const context = useWeb3Context()
  const connectorName = 'MetaMask'

  return (
    <div>
      <div className="container pt-4">
        <div className="admin-container">
          <Web3ConsumerComponent />

          {context.error && <p>An error occurred, check the console for details.</p>}

          <div>
            <button
              key={connectorName}
              disabled={context.connectorName === connectorName}
              onClick={() => context.setConnector(connectorName)}
            >
              Activate {connectorName}
            </button>
            {(context.active || (context.error && context.connectorName)) && (
              <button onClick={() => context.unsetConnector()}>
                {context.active ? 'Deactivate Connector' : 'Reset'}
              </button>
            )}
          </div>

          <hr />

          <div className="row admin-row">
            <div className="col-sm-6">
              <Create active={context.active} onCreate={addPostion} />
            </div>
            <div className="col-sm-6">
              <Edit active={context.active} onEdit={editPosition} />
            </div>
          </div>
          <div className="row admin-row">
            <div className="col-sm-6">
              <Archive active={context.active} onArchive={archivePosition} />
            </div>
            <div className="col-sm-6">
              <WithdrawTokens active={context.active} onWithdraw={withdrawTokens} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
