import React from 'react'
import { NavLink } from 'react-router-dom'

export const NavbarLinks = () => {
  return (
    <div>
      <li className="nav-item">
        <NavLink className="nav-link nav-link-custom" to="/" exact>
          Project directory
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link nav-link-custom" to="/archive">
          Archive Page
        </NavLink>
      </li>
      {
        // <li className="nav-item">
        //   <NavLink className="nav-link nav-link-custom" to="/admin">
        //     Admin Page
        //   </NavLink>
        // </li>
      }
    </div>
  )
}
