import React from 'react'

const format = n => {
  return n.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}

const formatTime = finishedAt => {
  const temp = new Date(finishedAt * 1000).toLocaleDateString().replace(/\//g, '.')
  const arr = temp.split('.')
  const newArr = [arr[1], arr[0], arr[2]]
  return newArr.join().replace(/,/g, '.')
}

export const Right = ({ position, isDone }) => {
  const date_future = position.finishedAt * 1000
  const date_now = new Date()

  let seconds = Math.floor((date_future - date_now) / 1000)
  let minutes = Math.floor(seconds / 60)
  let hours = Math.floor(minutes / 60)
  let days = Math.floor(hours / 24)

  hours = hours - days * 24
  minutes = minutes - days * 24 * 60 - hours * 60
  seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60

  return (
    <div style={{ paddingLeft: '70px' }}>
      <div className="main-right-parent-div">
        <div className="main-right-div">
          <a className="main-right-a-img" href={position.link} target="_blank">
            <img src={position.imageUrl} className="img-fluid main-right-img" alt="Responsive image" />
          </a>
          <div className="main-right-div-number">
            <div className="main-right-div-number-number">{position.id + 1}</div>
          </div>
        </div>
      </div>
      <div>
        <div className="main-right-soft-cup">{format(position.capInUSD)}$</div>
        <div className="main-right-budget">Project Budget</div>

        <div className="main-right-lifetime">Life time</div>
        <div className="main-right-lifetime-value">
          {position.finishedAt ? (isDone ? formatTime(position.finishedAt) : `${days}d ${hours}h ${minutes}m`) : null}
        </div>
      </div>
    </div>
  )
}
