import React, { useContext, useEffect } from 'react'
import { useWeb3Context } from 'web3-react'
import { EthereumContext } from '../../context/ethereum/ethereumContext'

export const NavbarRight = ({ account }) => {
  const { balance, fetchBalance } = useContext(EthereumContext)
  const { library } = useWeb3Context()

  useEffect(() => {
    fetchBalance({ web3: library, from: account })
  }, [])

  return (
    <div className="navbar-rigth-main d-flex justify-content-center row">
      <div className="col-sm-6 d-flex justify-content-center navbar-rigth-main-part-account align-items-center">
        {account ? `${account.slice(0, 8)}...` : `Not Found`}
      </div>
      <div className="col-sm-6 d-flex justify-content-center navbar-rigth-main-part-balance align-items-center">
        {balance ? Math.round(Number(library.utils.fromWei(balance, 'ether')) * 100) / 100 : 0} ISKRA
      </div>
    </div>
  )
}
