import React from 'react'
import mainLogo from '../../assets/logo.png'
import { useWeb3Context } from 'web3-react'
import { NavbarRight } from './NavbarRight'
import { NavbarLinks } from './NavbarLinks'

export const Navbar = () => {
  const { account } = useWeb3Context()

  return (
    <nav className="navbar position-fixed navbar-expand-lg navbar-custom">
      <div className="navbar-brand navbar-brand-custom">
        <div className="row">
          <img src={mainLogo} alt="Responsive image" />
          <div className="navbar-brand-custom-text">Lucem</div>
        </div>
      </div>

      <div className="container-fluid row">
        <div className="col-sm-6 d-flex justify-content-center">
          <ul className="navbar-nav navbar-left-main">
            <NavbarLinks />
          </ul>
        </div>
        <div className="col-sm-6 d-flex justify-content-center">
          {account ? <NavbarRight account={account} /> : null}
        </div>
      </div>
    </nav>
  )
}
