import { ADD_POSITION, FETCH_OWNER, FETCH_POSITIONS, FETCH_BALANCE, EDIT_POSITION, SHOW_LOADER } from '../constants'

const handlers = {
  [FETCH_BALANCE]: (state, { payload }) => ({
    ...state,
    balance: payload.balance
  }),
  [FETCH_POSITIONS]: (state, { payload }) => ({
    ...state,
    positions: payload.positions
  }),
  [SHOW_LOADER]: state => ({ ...state, loading: true }),
  DEFAULT: state => state
}

export const EthereumReducer = (state, action) => {
  const handle = handlers[action.type] || handlers.DEFAULT
  return handle(state, action)
}
