import React from 'react'
import { useWeb3Context } from 'web3-react'

import { TOKEN_CONTRACT_ADDRESS, TOKEN_CONTRACT_ABI } from '../../context/ethereum/token'

export const Vote = ({ position, onVote, isDone }) => {
  const context = useWeb3Context()
  const isVoted = position.voterInfo === '0' ? false : true

  let voteResult
  if (
    position.voteNo + position.voteYes >= 3 &&
    position.finishedAt <= new Date() / 1000 &&
    position.voteYes > position.voteNo
  ) {
    voteResult = 'Won the vote'
  } else {
    voteResult = 'Lost the vote'
  }

  const tokenContract = new context.library.eth.Contract(TOKEN_CONTRACT_ABI, TOKEN_CONTRACT_ADDRESS)

  const _voteYes = () => {
    onVote({
      values: {
        id: position.id,
        vote: 2,
        tokens: position.votePriceInTokens.toString()
      },
      contract: tokenContract,
      from: context.account,
      web3: context.library
    })
  }

  const _voteNo = () => {
    onVote({
      values: {
        id: position.id,
        vote: 1,
        tokens: position.votePriceInTokens.toString()
      },
      contract: tokenContract,
      from: context.account,
      web3: context.library
    })
  }

  if (!isDone) {
    return (
      <div className="row main-left-vote-custom">
        {!isVoted ? (
          <div className="col-sm-4 main-left-vote-custom-button">
            <button type="button" className="yesButton" onClick={_voteYes}>
              Vote YES
            </button>
          </div>
        ) : position.voterInfo === '2' ? (
          <div className="col-sm-4 main-left-vote-custom-button">
            <div className="main-left-vote-custom-button-voted-yes d-flex justify-content-center align-items-center">
              <div className="main-left-vote-custom-button-voted-yes-text">You voted YES</div>
            </div>
          </div>
        ) : (
          <div className="col-sm-4 main-left-vote-custom-button">
            <div className="main-left-vote-custom-button-voted-no d-flex justify-content-center align-items-center">
              <div className="main-left-vote-custom-button-voted-no-text">You voted NO</div>
            </div>
          </div>
        )}

        {!isVoted ? (
          <div className="col-sm-4 main-left-vote-custom-button">
            <button type="button" className="noButton" onClick={_voteNo}>
              Vote NO
            </button>
          </div>
        ) : null}

        <div className="col-sm-4 main-left-vote-custom-button">
          <div className="main-left-vote-custom-vote-title">Voice price</div>
          <div className="main-left-vote-custom-vote-price">
            {context.library.utils.fromWei(position.votePriceInTokens.toString(), 'ether')} ISKRA
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="main-left-vote-custom">
        {voteResult === 'Won the vote' ? (
          <div className="main-left-vote-custom-result-var-won">{voteResult}</div>
        ) : (
          <div className="main-left-vote-custom-result-var-lost">{voteResult}</div>
        )}
        <div className="main-left-vote-custom-result-static">Result</div>
      </div>
    )
  }
}
