import React from 'react'
import { useFormik } from 'formik'
import { useWeb3Context } from 'web3-react'
import DatePicker from './DatePicker'
import 'react-datepicker/dist/react-datepicker.css'

import {
  LOGIC_CONTRACT_ADDRESS,
  LOGIC_CONTRACT_ABI
} from '../../context/ethereum/contract'

export const Edit = ({ active, onEdit }) => {
  const context = useWeb3Context()

  const formik = useFormik({
    initialValues: {
      id: 0,
      name: '',
      description: '',
      imageUrl: '',
      link: '',
      capInUSD: 0,
      votePriceInTokens: 0,
      finishedAt: 0
    },
    onSubmit: values => {
      const logicContract = new context.library.eth.Contract(
        LOGIC_CONTRACT_ABI,
        LOGIC_CONTRACT_ADDRESS
      )

      onEdit({
        values,
        contract: logicContract,
        from: context.account,
        utils: context.library.utils
      })
    }
  })

  return (
    <div>
      <h1>Edit</h1>
      <form onSubmit={formik.handleSubmit}>
        <div className="text-input">
          <label htmlFor="id">Id &nbsp;&nbsp;</label>
          <input
            id="id"
            name="id"
            type="number"
            onChange={formik.handleChange}
            value={formik.values.id}
          />
        </div>

        <div className="text-input">
          <label htmlFor="name">Name &nbsp;&nbsp;</label>
          <input
            id="name"
            name="name"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.name}
          />
        </div>

        <div className="text-input">
          <label htmlFor="description">Description &nbsp;&nbsp;</label>
          <input
            id="description"
            name="description"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.description}
          />
        </div>

        <div className="text-input">
          <label htmlFor="imageUrl">Image url &nbsp;&nbsp;</label>
          <input
            id="imageUrl"
            name="imageUrl"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.imageUrl}
          />
        </div>

        <div className="text-input">
          <label htmlFor="link">Link &nbsp;&nbsp;</label>
          <input
            id="link"
            name="link"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.link}
          />
        </div>

        <div className="text-input">
          <label htmlFor="capInUSD">Cap In USD &nbsp;&nbsp;</label>
          <input
            id="capInUSD"
            name="capInUSD"
            type="number"
            onChange={formik.handleChange}
            value={formik.values.capInUSD}
          />
        </div>

        <div className="text-input">
          <label htmlFor="votePriceInTokens">
            Vote price in tokens &nbsp;&nbsp;
          </label>
          <input
            id="votePriceInTokens"
            name="votePriceInTokens"
            type="number"
            onChange={formik.handleChange}
            value={formik.values.votePriceInTokens}
          />
        </div>

        <div className="text-input">
          <label htmlFor="finishedAt">Finished at &nbsp;&nbsp;</label>
          <DatePicker
            id="finishedAt"
            name="finishedAt"
            type="string"
            value={formik.values.finishedAt}
            onChange={formik.setFieldValue}
          />
        </div>

        <button disabled={!active} type="submit">
          Submit
        </button>
      </form>
    </div>
  )
}
