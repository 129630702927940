import React from 'react'
import mainLogo from '../../assets/logo.png'

export const Footer = () => {
  return (
    <div className="footer-main">
      <div className="footer-main-padding row">
        <div className="col-sm-6">
          <div className="row">
            <img src={mainLogo} alt="Responsive image" />

            <div className="footer-main-text-left">
              <div className="footer-main-text-left-top">Lucem</div>
              <div className="footer-main-text-left-bottom">
                Copyright © 2019 Decentralized Children's Fund - Lucem. All rights reserved.
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 d-flex justify-content-end">
          <div className="row">
            <div className="footer-main-text-right">
              <div className="footer-main-text-right-top">Powered by</div>
              <div className="footer-main-text-right-bottom">elastoo</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
