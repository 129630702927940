import React, { Fragment } from 'react'
import { Positions } from '../components/Positions'
import { Loader } from '../components/Loader'
import { useWeb3Context } from 'web3-react'

export const Archive = () => {
  const context = useWeb3Context()

  context.setConnector('MetaMask')

  return (
    <div className="admin-container">
      <Fragment>{!context.active ? <Loader /> : <Positions isArchived={true} />}</Fragment>
    </div>
  )
}
